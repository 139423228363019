<template>
  <div class="swiper" ref="swiper">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import Swiper, { Autoplay, Mousewheel, Scrollbar } from "swiper8"
import BleachSwiper from "./BleachSwiperLib"
import "swiper8/swiper.scss"
import "swiper8/modules/scrollbar/scrollbar.scss"

export default {
  props: {
    options: Object
  },

  data: () => ({
    swiperInstance: null,
    defaultOptions: {
      modules: [Autoplay, Mousewheel, Scrollbar]
    }
  }),

  mounted() {
    this.initSwiper()
  },

  beforeDestroy() {
    this.destroySwiper()
  },

  methods: {
    destroySwiper() {
      if (this.swiperInstance && !this.swiperInstance.destroyed) {
        this.swiperInstance.destroy(true, false)
      }
    },

    resetSwiper() {
      this.destroySwiper()
      this.initSwiper()
    },

    initSwiper() {
      this.swiperInstance = new Swiper(this.$refs.swiper, {
        ...this.defaultOptions,
        ...this.options
      })
      BleachSwiper.bindSwiperEvents(this.swiperInstance, this.$emit.bind(this))
    }
  }
}
</script>

<style></style>
