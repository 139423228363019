<template>
  <div class="swiper-slide">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
