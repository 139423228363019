const SWIPER_EVENTS = [
  "init",
  "beforeDestroy",
  "slideChange",
  "slideChangeTransitionStart",
  "slideChangeTransitionEnd",
  "slideNextTransitionStart",
  "slideNextTransitionEnd",
  "slidePrevTransitionStart",
  "slidePrevTransitionEnd",
  "transitionStart",
  "transitionEnd",
  "touchStart",
  "touchMove",
  "touchMoveOpposite",
  "sliderMove",
  "touchEnd",
  "click",
  "tap",
  "doubleTap",
  "imagesReady",
  "progress",
  "reachBeginning",
  "reachEnd",
  "fromEdge",
  "setTranslate",
  "setTransition",
  "resize",
  "observerUpdate",
  "beforeLoopFix",
  "loopFix"
]

export default {
  bindSwiperEvents: (swiper, emit) => {
    SWIPER_EVENTS.forEach(eventName => {
      swiper.on(eventName, (...args) => {
        emit(eventName, ...args)
        // const kebabcaseName = kebabcase(eventName)
        // if (kebabcaseName !== eventName) {
        //   emit(kebabcaseName, ...args)
        // }
      })
    })
  }
}
