<template>
  <div class="bp-auto-heading" ref="holder">
    <component
      :is="tag"
      class="bp-auto-heading__text"
      ref="text"
      :style="{ fontSize: `${getCurrentFontSize}rem` }"
      :class="[
        fontClass,
        {
          'bp-auto-heading__text--too-long': tooDamnLong
        }
      ]"
      ><slot
    /></component>
  </div>
</template>
<script>
import styles from "../../styles/settings.scss"
import textStyles from "../../styles/typography.scss"
import { mapState, mapGetters } from "vuex"

export default {
  props: {
    fontClass: String,
    tag: {
      type: String,
      default: "h1"
    },
    // lines is deprecated, header should always big as big as possible?
    lines: {
      type: Number,
      default: 1
    }
  },

  data: () => ({
    styles,
    textStyles,
    currentActiveBreakpoint: null,
    currentFontSize: null,
    minFontSize: null,
    maxFontSize: null,
    tooDamnLong: false
  }),

  created() {
    this.setRanges()
  },

  mounted() {
    this.reflowText()
  },

  methods: {
    reflowText() {
      this.tooDamnLong = false
      const holder = this.$refs["holder"]
      const el = this.$refs["text"]
      this.$nextTick(() => {
        if (el.scrollWidth > holder.clientWidth) {
          if (this.currentFontSize >= this.minFontSize + 0.1) {
            this.currentFontSize = this.currentFontSize - 0.1
            this.reflowText()
          } else {
            this.tooDamnLong = true
          }
        }
      })
    },

    setRanges() {
      // If a fontClass is declared, use it
      if (this.fontClass) {
        // get the current active font style from the available options (not all fonts have styles set for all breakpoints)
        // loop through the styles until we have a match
        // eg if we have a style for xs, lg and currentwidth is md, first check if there's an md style. If there's not, check for sm, and so on.

        let _maxFontSize

        for (
          let i = this.allBreakpoints.findIndex(
            bp => bp == this.GET_CURRENT_BREAKPOINT
          );
          i >= 0;
          i--
        ) {
          let _styleForBreakpoint =
            textStyles[
              `TEXT_SIZE_${this.fontClass.split(" ")[0]}_${
                this.allBreakpoints[i].breakpoint
              }`
            ]
          this.debug.log(
            `TEXT_SIZE_${this.fontClass.split(" ")[0]}_${
              this.allBreakpoints[i].breakpoint
            }`,
            _styleForBreakpoint
          )
          if (_styleForBreakpoint) {
            _maxFontSize = _styleForBreakpoint
            break
          }
        }

        this.maxFontSize = _maxFontSize
        this.minFontSize = this.maxFontSize * 0.66
        this.currentFontSize = this.maxFontSize
      }
    }
  },

  computed: {
    ...mapState("breakpoints", ["allBreakpoints", "currentWidth"]),
    ...mapGetters("breakpoints", [
      "GET_CURRENT_BREAKPOINT",
      "GET_IS_BELOW_BREAKPOINT"
    ]),

    getCurrentFontSize() {
      return this.currentFontSize
    }
  },

  watch: {
    currentWidth() {
      this.setRanges()
      this.reflowText()
    }
  }
}
</script>
<style lang="scss">
.bp-auto-heading {
  position: relative;
  margin-bottom: 0.2em;
  width: 100%;

  &__text {
    // word-break: break-all;
    // word-wrap: break-word;
    transition: opacity 0.1s linear;
    line-height: normal;

    &--too-long {
      // word-break: normal;
      // word-wrap: normal;
    }
  }
}
</style>
